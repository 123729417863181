import isEmpty from 'lodash/isEmpty'
import { GameResponse, NormalizedGame } from '../../types'

export const normalizeGame = (game: GameResponse): NormalizedGame => ({
  assign: game.assign,
  countActivitiesAfterGuide: game.countActivitiesAfterGuide,
  coverImageUrl: game?.coverImageUrl || null,
  earnedReward: game.playerInformation.earnedReward,
  endDateTime: game.expiresAt,
  fulfilledPayout: game.playerInformation.fulfilledPayout,
  gameType: game.type,
  goal: game.rules.goal,
  hasPlayerInformation: !isEmpty(game.playerInformation),
  hasThreshold: game.playerInformation.hasThreshold,
  hasManagerView: game.managerViewEnabled,
  id: game.id,
  isRewardImmediatelyDistributed: game.isRewardImmediatelyDistributed,
  isSingleGoalReward: game.isSingleGoalReward,
  jackpot: game.jackpot,
  lastUpdatedAt: game.lastUpdatedAt,
  limitWinners: game.limitWinners,
  maximumWinners: game.maximumWinners,
  metric: game.rules.metric,
  name: game.name,
  numberOfWinners: game.numberOfWinners,
  pendingPayout: game.playerInformation.pendingPayout,
  place: game.playerInformation.ranking,
  playerCount: game?.playerCount,
  prizeAmount: parseInt(game.rewards[0].value),
  prizeType: game.rewards[0]?.type,
  progressPercentage: game.playerInformation.progressPercentage,
  progressReason: game.playerInformation.progressReason,
  rewards: game.rewards,
  rewardsAwardedCount: game?.rewardsAwardedCount,
  rules: game.rules,
  score: game.playerInformation.score,
  startDateTime: game.startsAt,
  status: game.status,
  threshold: game.playerInformation.threshold,
  totalScore: game.playerInformation.totalScore,
  unit: game.rules.unit,
  userId: game.winner?.id,
  userImageUrl: game.winner?.imageUrl,
  userName: game.winner?.name,
})
